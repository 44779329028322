/* eslint no-console:0 */
import "core-js/stable"
import Rails from "@rails/ujs"

Rails.start()
console.log("Hello visitor !")

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faFileSpreadsheet } from "@fortawesome/pro-light-svg-icons/faFileSpreadsheet";

import { faTasks } from "@fortawesome/pro-light-svg-icons/faTasks";
import { faExchangeAlt } from "@fortawesome/pro-light-svg-icons/faExchangeAlt";
import { faDatabase } from "@fortawesome/pro-light-svg-icons/faDatabase";
import { faFolders } from "@fortawesome/pro-light-svg-icons/faFolders";
import { faHistory } from "@fortawesome/pro-light-svg-icons/faHistory";

library.add(faFileSpreadsheet, faTasks, faExchangeAlt, faDatabase, faFolders, faHistory);
dom.watch();

import $ from "jquery"
window.$ = $

function scrollHeader() {
  var header = $("#header")[0]
  var sticky = header.offsetTop
  window.pageYOffset > sticky
    ? $("body").addClass("scrolled")
    : $("body").removeClass("scrolled")
}

window.$(document).ready(function() {
  scrollHeader()

  $("#toogler").on('click', function() {
    $(this).toggleClass("open")
  })
})

$(window).on("scroll", function() {
  scrollHeader()
})
